<template>
  <svg :class="`icon ${iconName}`"><use :xlink:href="require('@/assets/symbol-defs.svg') + `#${iconName}`" /></svg>
</template>

<script>
export default {
  props: {
    i: {
      type: String,
      required: true
    }
  },
  computed: {
    iconName () {
      return `icon-${this.i}`
    }
  }
}
</script>

<style scoped>
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
</style>
