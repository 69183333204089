<template>
  <div>
    <div id="layout-bg" class="p-1 flex justify-between items-center">
      <h1>
        <NuxtLink to="/" class="font-megrim font-normal text-white text-6xl text-center md:px-6 md:text-left">
          ALKOV
        </NuxtLink>
      </h1>
      <div v-if="loggedIn" class="text-xl">
        <NuxtLink v-if="user.admin" to="/admin" class="text-white mx-3 hover:text-gray-100" title="Administration">
          <Icon i="wrench" />
        </NuxtLink>
        <NuxtLink to="/profile" class="text-white mx-3 hover:text-gray-100" title="Profil">
          <Icon i="user" />
        </NuxtLink>
      </div>
    </div>
    <div class="mt-3">
      <Nuxt :class="containerClass" class="px-4" />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Icon from '~/components/Icon.vue'

const { mapState } = createNamespacedHelpers('auth')
export default {
  components: { Icon },
  props: {
    wide: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState([
      'user',
      'loggedIn'
    ]),
    containerClass () {
      if (this.wide) {
        return ''
      }

      return 'container mx-auto'
    }
  },
  async mounted () {
    const workbox = await window.$workbox
    if (workbox) {
      workbox.addEventListener('installed', (event) => {
        if (event.isUpdate) {
          window.location.reload(true)
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>
#layout-bg {
  @apply from-indigo-400 to-purple-900;
  background-image: url('~assets/hexellence.png'), linear-gradient(to right, var(--tw-gradient-stops))
}
</style>
