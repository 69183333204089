<template>
  <div class="text-center pt-4">
    <Icon class="text-9xl text-gray-200" i="warning" />
    <h1 class="text-4xl mb-4">
      {{ errorMessage }}
    </h1>
    <NuxtLink to="/">
      Home page
    </NuxtLink>
  </div>
</template>

<script>
import Icon from '~/components/Icon.vue'
export default {
  name: 'Error',
  components: { Icon },
  layout: 'default',
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  computed: {
    errorMessage () {
      if (this.error.statusCode === 404) {
        return 'Page not found'
      } else {
        return 'An error occured'
      }
    }
  }
}
</script>
