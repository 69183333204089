import Middleware from './middleware'
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime'

// Active schemes
import { Oauth2Scheme } from '~auth/runtime'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {
  "resetOnError": true,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": false,
  "watchLoggedIn": true,
  "redirect": {
    "login": "/login",
    "logout": "/",
    "home": "/profile",
    "callback": "/login"
  },
  "vuex": {
    "namespace": "auth"
  },
  "cookie": {
    "prefix": "auth.",
    "options": {
      "path": "/",
      "maxAge": 604800,
      "secure": true
    }
  },
  "localStorage": false,
  "defaultStrategy": "twitch"
}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // twitch
  $auth.registerStrategy('twitch', new Oauth2Scheme($auth, {
  "endpoints": {
    "authorization": "https://id.twitch.tv/oauth2/authorize?claims={\"id_token\":{\"preferred_username\":null}}",
    "token": "https://oktopus.areku.fr/auth/token",
    "userInfo": "https://oktopus.areku.fr/me",
    "logout": false
  },
  "token": {
    "property": "access_token",
    "type": "Bearer"
  },
  "refresh_token": {
    "property": false
  },
  "responseType": "code",
  "grantType": "authorization_code",
  "clientId": "el58bakdinnd4vxyss0wdf8hkymtqs",
  "scope": [
    "openid",
    "user:read:subscriptions",
    "viewing_activity_read"
  ],
  "codeChallengeMethod": "S256",
  "acrValues": "",
  "audience": "",
  "autoLogout": false,
  "name": "twitch"
}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}
