<template>
  <div>
    <Nuxt />
  </div>
</template>

<script>
export default {
  name: 'LayoutFullpage',
  layout: 'fullpage',
  async mounted () {
    const workbox = await window.$workbox
    if (workbox) {
      workbox.addEventListener('installed', (event) => {
        if (event.isUpdate) {
          window.location.reload(true)
        }
      })
    }
  }
}
</script>

<style>
</style>
