export default function ({ $axios }, inject) {
  const oktopus = $axios.create({
    headers: {
      common: {
        Accept: 'application/json, text/plain, */*'
      }
    }
  })

  oktopus.setBaseURL(process.env.chapoBackendUrl)

  inject('oktopus', oktopus)
}
