<template>
  <div id="layout-bg" class="p-1">
    <h1>
      <NuxtLink to="/" class="font-megrim font-normal text-white text-6xl text-center mb-8 pt-2 md:px-6 md:text-left">
        ALKOV
      </NuxtLink>
    </h1>
    <Nuxt class="container mx-auto" />
  </div>
</template>

<script>
export default {
  name: 'LayoutEscape',
  layout: 'escape',
  async mounted () {
    const workbox = await window.$workbox
    if (workbox) {
      workbox.addEventListener('installed', (event) => {
        if (event.isUpdate) {
          window.location.reload(true)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#layout-bg {
  @apply min-h-screen from-indigo-400 to-purple-900;
  background-image: url('~assets/hexellence.png'), linear-gradient(to right, var(--tw-gradient-stops))
}
</style>
