export const state = () => ({
  inventory: []
})

export const mutations = {
  set_inventory (state, inventory) {
    state.inventory = inventory
  }
}

export const actions = {
  async loadInventory ({ commit }) {
    const inventory = await this.$oktopus.$get('chapo/inventory')
    commit('set_inventory', inventory.data)

    return inventory
  }
}
