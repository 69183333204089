<template>
  <TheLayoutHeader :wide="true" />
</template>

<script>
import TheLayoutHeader from '~/components/TheLayoutHeader'

export default {
  name: 'LayoutDefaultWide',
  components: { TheLayoutHeader }
}
</script>
