<template>
  <TheLayoutHeader />
</template>

<script>
import TheLayoutHeader from '~/components/TheLayoutHeader'

export default {
  name: 'LayoutDefault',
  components: { TheLayoutHeader }
}
</script>
